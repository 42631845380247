import App from '~app';

new App({
  name: 'my-app',
  app: () => import(/* webpackMode: 'lazy' */ `./apps/my-app/App.vue`),
  async setup(app) {
    // const PluginName = (await import('/path/to/plugin')).default;
    // app.use(plugin);
    return app;
  },
});

new App({
  name: 'frog-daddy',
  app: () => import(/* webpackMode: 'lazy' */ `./apps/frog-daddy/App.vue`),
  async setup(app) {
    // const PluginName = (await import('/path/to/plugin')).default;
    // app.use(plugin);
    return app;
  },
});

