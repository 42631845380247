import processProps from './utils/_processProps';
class App {
  constructor(options) {
    options.baseElement = options.baseElement || HTMLElement;
    options.setup = options.setup || (app => Promise.resolve(app));
    this.#registerVueApp(options);
  }
  #registerVueApp(options) {
    customElements.define(
      options.name,
      class extends options.baseElement {
        props = {};
        constructor() {
          super();
        }
        async connectedCallback() {
          const createApp = await import(`vue`).then(
            module => module.createApp
          );
          const app = await options.app().then(module => module.default);
          const children = [...this.children].map(child =>
            child.cloneNode(true)
          );

          this.props = processProps(this);
          (
            await options.setup.call(
              this,
              createApp(app, this.props),
              this.props,
              children
            )
          ).mount(this);
        }
      }
    );
  }
}

export { App, App as default };
