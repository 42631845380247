const camelCase = s => s.replace(/-./g, x => x[1].toUpperCase());
export default function (el) {
  const props = el.getAttributeNames().reduce((props, name) => {
    if (!name.startsWith(':') && !name.startsWith('data-')) return props;
    let value = el.getAttribute(name);
    try {
      if (!isNaN(value)) value = Number(value);
      else if (value.toLowerCase() === 'true') value = true;
      else if (value.toLowerCase() === 'false') value = false;
      else value = JSON.parse(value);
    } finally {
      if (name.startsWith(':')) el.removeAttribute(name);
      name = camelCase(name.replace(':', '').replace('data-', ''));
      props[name] = value;
      return props;
    }
  }, {});
  return props;
}
